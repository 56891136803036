import React, { useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Grid, InputLabel, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import "./styles.scss";
import { INDIAN_COUNTRY_CODE, isEmpty, setSymbolOnEmpty, valueWithCurrencySymbol } from '../../utils';
import { When } from 'react-if';
import { getUser, post } from '../../AppRedux/Actions';
import FormBuilder from '../../components/FormBuilder/FormBuilder';
import SubmitButton from '../../components/Buttons/CustomButton';
import { FaCommentMedical } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import { ADD_EMPLOYEE_PAYROLL_REMARKS } from '../../InternationalPath';
import { round } from 'lodash';


const InputContainer = styled('div')({
    marginTop: 20
});

const InputSection = styled('div')({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    marginTop: 12,
    marginBottom: 12
});

const InputValue = styled('div')({
    fontSize: 14
});

const InputName = styled('div')({
    fontSize: 14
});


const AddRemarks = React.forwardRef((props, ref) => {

    const { remarkValue, EMPLOYEE_ID } = props;

    console.log(props);

    const dispatch = useDispatch();

    const { filterResult: { month_year = null }, finalPayrolls, SetFinalPayrolls } = usePayrollHook();

    const [remark, setRemark] = useState(remarkValue ?? "");

    const isDisabled = useMemo(() => {

        return remarkValue === remark;

    }, [remark]);

    function handleAddRemark(e) {
        const { value: getRemarkValue } = e.target;
        setRemark(getRemarkValue);
    }

    function submitRemark() {
        const payload = {
            emp_id: EMPLOYEE_ID,
            payroll_month_year: month_year,
            remark
        }
        post(dispatch, '', ADD_EMPLOYEE_PAYROLL_REMARKS, payload, {}, (res) => {

            if (EMPLOYEE_ID) {
                finalPayrolls[EMPLOYEE_ID].REMARKS = remark;
                SetFinalPayrolls({ ...finalPayrolls });
            }
        })

    }

    return (<Box ref={ref}>
        <FormBuilder size="xs" fields={[{
            type: 'textarea',
            name: 'remark',
            label: 'Add Remarks',
            required: true,
            minRows: 3
        }]} values={{ remark: remark }} inputHandler={handleAddRemark}>
            <Grid container item sm="auto" style={{
                marginLeft: "auto"
            }} alignItems="center" gap={3}>
                <Grid item>
                    <SubmitButton
                        disabled={isDisabled}
                        variant="contained"
                        onClick={submitRemark}
                    >Submit Remark
                    </SubmitButton>
                </Grid>
            </Grid>
        </FormBuilder>
    </Box>);
});


function EmployeePayroll(props) {
    const scrollRef = useRef(null);
    const ACTIVE_COUNTRY = getUser('active_country_id');
    const { payrollDetail, handleCloseRightDrawer } = props;
    const { emp_name,
        emp_code,
        NET_PAYABLE,
        computed_gross,
        earning,
        allowance,
        DEDUCTION,
        deduction,
        compliances,
        employer_deduction,
        miscellaneous_heads,
        computed_obj,
        ot,
        emp_arrear,
        emp_bonus_heads,
        reimbursement = [],
        bonus = [],
        statutory_bonus,
        loan_amount,
        adv_amount,
        trip_amount,
        EMPLOYEE_ID,
        REMARKS
    } = payrollDetail;

    function formateNumber(value) {
        value = setSymbolOnEmpty(value, 0);
        // let fixed = ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE ? 0 : 2;
        value = round(Number(value) || 0, 3);
        return valueWithCurrencySymbol(value)
    }

    const grosspayDetails = [...earning, ...allowance, ...reimbursement, ...bonus, ...emp_bonus_heads, ...miscellaneous_heads.earning.map((item) => {
        return { HEAD_NAME: item.name, head_amount: item.amount }
    })];
    const Alldeuctions = [...deduction, ...miscellaneous_heads.deduction.map((item) => {
        return { HEAD_NAME: item.name, head_amount: item.amount }
    })];
    const compliancesList = compliances?.map((item) => {
        return { HEAD_NAME: item.HEAD_NAME, head_amount: item.head_amount }
    });

    const employerDeductionList = employer_deduction?.map((item) => {
        return { HEAD_NAME: item.HEAD_NAME, head_amount: item.head_amount }
    });

    return (
        <Stack direction="column" sx={{ width: 400 }} role="presentation" className="edit-employee h-100">
            <Box padding="8px 10px 2px" boxShadow="0px 0px 2px gray">
                <When condition={ACTIVE_COUNTRY != INDIAN_COUNTRY_CODE}>
                    <Button
                        startIcon={<FaCommentMedical />}
                        sx={{ color: "#5274e0", fontWeight: 600 }}
                        onClick={() => scrollRef.current.scrollIntoView({ behavior: "smooth" })}
                    >
                        Add Remark
                    </Button>
                </When>
                <Box sx={{ float: 'right', m: 1, cursor: 'pointer' }}><CloseIcon sx={{ height: 20, width: 20 }} onClick={handleCloseRightDrawer} /></Box>
            </Box>
            <Box className="spacing h-100" overflow="auto">
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='body2' component="div" sx={{ color: '#4D70FF' }}>{setSymbolOnEmpty(emp_name, "-")}</Typography>
                        <Typography component="div">Net Payable</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography component="div">Emp  Code :{setSymbolOnEmpty(emp_code, "-")}</Typography>
                        <Typography component="div">{formateNumber(NET_PAYABLE)}</Typography>
                    </Stack>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Gross Salary</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>{formateNumber(computed_gross)}</Typography>
                    </Stack>
                    <InputContainer>
                        {
                            grosspayDetails?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }

                        {
                            emp_arrear?.earn_head?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })

                        }
                        <When condition={statutory_bonus > 0}>
                            <InputSection>
                                <InputName>Statutory Bonus</InputName>
                                <InputValue>{formateNumber(statutory_bonus ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={ot.is_ot_enabled}>
                            <InputSection>
                                <InputName>OT</InputName>
                                <InputValue>{formateNumber(ot.ot_amount)}</InputValue>
                            </InputSection>

                            <InputSection>
                                <InputName>Production Incentive</InputName>
                                <InputValue>{formateNumber(ot?.extra_ot_amount)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={loan_amount > 0}>
                            <InputSection>
                                <InputName>Loans</InputName>
                                <InputValue>{formateNumber(loan_amount ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={adv_amount > 0}>
                            <InputSection>
                                <InputName>Advance</InputName>
                                <InputValue>{formateNumber(adv_amount ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={trip_amount > 0}>
                            <InputSection>
                                <InputName>Trip</InputName>
                                <InputValue>{formateNumber(trip_amount ?? 0)}</InputValue>
                            </InputSection>
                        </When>
                    </InputContainer>
                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                        <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Deductions</Typography>
                        <Typography variant='body2' component="div" sx={{ color: '#FF3333' }}>{formateNumber(DEDUCTION)}</Typography>
                    </Stack>
                    <InputContainer>
                        {
                            Alldeuctions?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })
                        }
                        {
                            emp_arrear?.ded_head?.map((item) => {
                                return (
                                    <InputSection>
                                        <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                        <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                    </InputSection>
                                )
                            })

                        }
                        <When condition={ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE}>
                            <InputSection>
                                <InputName>Income tax</InputName>
                                <InputValue>{formateNumber(computed_obj?.tds || 0)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={loan_amount < 0}>
                            <InputSection>
                                <InputName>Loans</InputName>
                                <InputValue>{formateNumber((Number(loan_amount) || 0) * -1)}</InputValue>
                            </InputSection>
                        </When>
                        <When condition={adv_amount < 0}>
                            <InputSection>
                                <InputName>Advance</InputName>
                                <InputValue>{formateNumber((Number(adv_amount) || 0) * -1)}</InputValue>
                            </InputSection>
                        </When>
                    </InputContainer>
                </Box>
                <When condition={ACTIVE_COUNTRY == INDIAN_COUNTRY_CODE}>
                    <Box>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                            <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Compliances</Typography>
                            <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>-</Typography>
                        </Stack>
                        <InputContainer>
                            {
                                compliancesList?.map((item) => {
                                    return (
                                        <InputSection>
                                            <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                            <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                        </InputSection>
                                    )
                                })
                            }
                        </InputContainer>
                    </Box>
                </When>
                <When condition={ACTIVE_COUNTRY != INDIAN_COUNTRY_CODE}>
                    <Box>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#E6ECFF26', mt: 3, p: 0.5 }}>
                            <Typography variant='body2' component="div" sx={{ color: '#80ACFF' }}>Employer Deductions</Typography>
                            <Typography variant='body2' component="div" sx={{ color: '#00CD37' }}>-</Typography>
                        </Stack>
                        <InputContainer>
                            {
                                employerDeductionList?.map((item) => {
                                    return (
                                        <InputSection>
                                            <InputName>{item?.DISPLAY_NAME || item?.HEAD_NAME}</InputName>
                                            <InputValue>{formateNumber(item?.head_amount)}</InputValue>
                                        </InputSection>
                                    )
                                })
                            }
                        </InputContainer>
                    </Box>
                </When>
                <When condition={ACTIVE_COUNTRY != INDIAN_COUNTRY_CODE}>
                    <Divider sx={{ marginBottom: 2 }} />
                    <AddRemarks ref={scrollRef} remarkValue={REMARKS} EMPLOYEE_ID={EMPLOYEE_ID} />
                </When>
            </Box>
        </Stack>
    )
}

export default EmployeePayroll;