import React, { useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from './AppRedux/store';
import Components from "./Common";

import { PayrollProvider } from "./hooks/usePayrollHook";
import ModalGroup from "./components/ModalGroup/ModalGroup";
import ThemeSetup from "./Common/themeOptions";
import { CssBaseline } from "@mui/material";
import CustomSelect from "./components/FormBuilder/CustomSelect";
import { I18nProvider } from './utilsLang/i18n/i18n';
const HEADER_HEIGHT = 49;

export const getHeightBasedonSubMenu = (height) => {
  let countHeight = height;
  const subHeader = document.querySelector('.subheader');
  const secondarySubHeader = document.querySelector('.secondary-subheader');

  if (subHeader) countHeight += HEADER_HEIGHT;
  if (secondarySubHeader) countHeight += HEADER_HEIGHT;

  return `${countHeight}px`;
}

const App = () => {
  return (
    <Provider store={store}>
      <PayrollProvider>
        <I18nProvider>
        <ThemeSetup>
          <CssBaseline />
          <ModalGroup>
            <BrowserRouter basename="/">
              <Switch>
                <Route path="/" component={Components} />
              </Switch>
            </BrowserRouter>
          </ModalGroup>
        </ThemeSetup>
        </I18nProvider>
      </PayrollProvider>
    </Provider>
  )
};

export default App;

