import { createContext, useContext, useState, useEffect } from 'react';

const SUPPORTED_LANGUAGES = ['en', 'es', 'fr', 'ar'];
const DEFAULT_LANGUAGE = 'en';
const FALLBACK_LANGUAGE = 'en';
const LANGUAGE_STORAGE_KEY = 'app_language';

function getInitialLanguage() {

  const storedLanguage = localStorage.getItem(LANGUAGE_STORAGE_KEY);
  if (storedLanguage && SUPPORTED_LANGUAGES.includes(storedLanguage)) {
    return storedLanguage;
  }

  const browserLanguage = navigator.language.split('-')[0];
  if (SUPPORTED_LANGUAGES.includes(browserLanguage)) {
    return browserLanguage;
  }

  return DEFAULT_LANGUAGE;
}

const I18nContext = createContext();

export function I18nProvider({ children }) {
  const [currentLanguage, setLanguage] = useState(getInitialLanguage);
  const [translations, setTranslations] = useState({});
  const [fallbackTranslations, setFallbackTranslations] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const config = {
    keySeparator: ".", 
  };

  useEffect(() => {
    async function loadFallbackTranslations() {
      try {
        const fallbackModule = await import(`../locales/${FALLBACK_LANGUAGE}.json`);
        setFallbackTranslations(fallbackModule.default);
      } catch (error) {
        console.error(`Failed to load fallback translations (${FALLBACK_LANGUAGE}):`, error);
      }
    }

    loadFallbackTranslations();
  }, []);

  useEffect(() => {
    async function loadTranslations() {
      setIsLoading(true);
      try {

        const translationModule = await import(`../locales/${currentLanguage}.json`);
        setTranslations(translationModule.default);

        localStorage.setItem(LANGUAGE_STORAGE_KEY, currentLanguage);
      } catch (error) {
        console.error(`Failed to load translations for ${currentLanguage}:`, error);

        if (currentLanguage !== FALLBACK_LANGUAGE) {
          setTranslations({}); 
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (currentLanguage !== FALLBACK_LANGUAGE) {
      loadTranslations();
    } else {

      setTranslations(fallbackTranslations);
      setIsLoading(false);

      localStorage.setItem(LANGUAGE_STORAGE_KEY, currentLanguage);
    }
  }, [currentLanguage, fallbackTranslations]);

  const translate = (key) => {

    if (config.keySeparator && key.includes(config.keySeparator)) {
      const keys = key.split(config.keySeparator);

      if (!isLoading && translations) {
        let result = translations;
        for (const k of keys) {
          if (result && typeof result === 'object' && k in result) {
            result = result[k];
          } else {
            result = null;
            break;
          }
        }
        if (result && typeof result === 'string') {
          return result;
        }
      }

      if (fallbackTranslations) {
        let result = fallbackTranslations;
        for (const k of keys) {
          if (result && typeof result === 'object' && k in result) {
            result = result[k];
          } else {
            result = null;
            break;
          }
        }
        if (result && typeof result === 'string') {
          return result;
        }
      }
    } else {

      if (!isLoading && translations && translations[key]) {
        return translations[key];
      }

      if (fallbackTranslations && fallbackTranslations[key]) {
        return fallbackTranslations[key];
      }
    }

    console.warn(`No translation found for key: ${key} in any language`);
    return key;
  };

  const changeLanguage = (lang) => {
    if (SUPPORTED_LANGUAGES.includes(lang)) {
      setLanguage(lang);
    }
  };

  return (
    <I18nContext.Provider value={{ 
      currentLanguage, 
      translate, 
      changeLanguage, 
      isLoading,
      isFallbackActive: isLoading || Object.keys(translations).length === 0,
      config,
      supportedLanguages: SUPPORTED_LANGUAGES
    }}>
      {children}
    </I18nContext.Provider>
  );
}

export function useTranslation() {
  const context = useContext(I18nContext);
  if (!context) {
    throw new Error('useTranslation must be used within an I18nProvider');
  }
  return context;
}

export function Trans({ id, children }) {
  const { translate } = useTranslation();

  if (id) {
    return translate(id);
  }

  if (typeof children === 'string') {
    return translate(children);
  }

  return children;
}

export function withTranslation(Component) {
  return function TranslatedComponent(props) {
    const { translate } = useTranslation();

    const enhancedProps = {
      ...props,
      t: translate
    };

    return <Component {...enhancedProps} />;
  };
}

export function LanguageSelector() {
  const { currentLanguage, changeLanguage, supportedLanguages } = useTranslation();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <select 
        value={currentLanguage}
        onChange={(e) => changeLanguage(e.target.value)}
      >
      </select>
    </div>
  );
}