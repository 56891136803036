import { Box, Grid, Stack } from '@mui/material'
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { date_format, setSymbolOnEmpty } from '../../utils';
import "./styles.scss";
import CustomModal from '../../components/CustomModal/CustomModal';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import { displayLoader, fetchDataByThread, hideLoader, post } from '../../AppRedux/Actions';
import { useDispatch } from 'react-redux';
import { usePayrollHook } from '../../hooks/usePayrollHook';
import { PROCESS_EMPLOYEES_ARREAR } from '../../constants';
import { Else, If, Then, When } from 'react-if';
import CircleButton from '../../components/Buttons/CircleButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import List from '../../components/CustomTable/List';
import { arrearDetails, viewArrearKeys } from './constant';
import { styled } from '@mui/styles';
import { CustomTable } from '@zimyo/components';
import StatusColumn from '../../components/CustomTableData/StatusColumn';

const Title = styled(Box)({
  textTransform: 'capitalize',
  marginBottom: 6,
  padding: '0.2rem',
  borderRadius: "0.2rem",
  width: "fit-content",
  fontWeight: 'bold'
});


export function ArrearBreakDownDetails(props) {
  const { selectedRow = {} } = props;
  return (
    <>
      <Grid container xs={12} spacing={2} mt={1} mb={1}>
        {arrearDetails.map(({
          name,
          id
        }) => {
          return <Grid item lg={4} sm={6} xs={6}>
            <Stack direction='row' justifyContent="space-between" sx={{
              background: "#d8d6d6",
              padding: "0.25rem",
              borderRadius: "0.2rem",
              color: "#4c4c4e"
            }}>
              <Box fontWeight='bold'>{name}</Box>
              <Box>{setSymbolOnEmpty(selectedRow?.[id], "--")}</Box>
            </Stack>
          </Grid>;
        })}
      </Grid>
      <Grid container xs={12} spacing={2}>
        {viewArrearKeys.map(keys => {
          return <Grid item md={6} sm={12} xs={12}>
            <Title>{keys?.replace("_", " ")}</Title>
            <List sx={{
              maxHeight: 300
            }} headstyle={{
              backgroundColor: "#d8d6d6",
              fontSize: 14,
              fontWeight: 400,
              color: "#4c4c4e"
            }} rows={selectedRow?.[keys] ?? []} columns={[{
              id: 'HEAD_NAME',
              label: <b>Name</b>
            }, {
              id: 'ARREAR_AMOUNT',
              label: <b>Amount</b>,
              align: "right"
            }]} />
          </Grid>;
        })}
      </Grid>
    </>);
}


function ArrearBreakDown({ breakdown }) {
  const dispatch = useDispatch();
  const { arrears = [], isDeletedHide = false } = breakdown;
  const [isClickdelete, setIsClickdelete] = useState(false);
  const [isClickview, setIsClickview] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [arrearList, setArrearList] = useState(arrears || []);
  const { employeesArrear, checkedIds, filterResult, setEmployeesArrear, slugs, setSlugs } = usePayrollHook()
  /* Function call */
  function handleDeleteArrear(row) {
    setSelectedRow({ ...row });
    setIsClickdelete(true);
  }

  function handleViewArrear(row) {
    setSelectedRow({ ...row });
    setIsClickview(true);
  }

  function fetchLatestArrearList() {
    const payload = {
      emp_id: Object.keys(checkedIds),
      ...filterResult,

    }

    displayLoader(dispatch);

    post(dispatch, '', PROCESS_EMPLOYEES_ARREAR, payload, {}, (data) => {
      const { pending_count = 0, thread_id = null, arrear = {}, errors = [], slug_info = {} } = data;
      setEmployeesArrear({ ...arrear });
      setSlugs({ ...slugs, "2": slug_info });
      let inital_total = (Object.keys(arrear)?.length ?? 0) + (errors?.length ?? 0);
      dispatch({ type: 'PERCENTAGE', payload: { percentage: inital_total / (inital_total + pending_count) * 100 } });
      fetchDataByThread(dispatch, pending_count, thread_id, inital_total, ({ response = {} }) => {
        setEmployeesArrear({ ...employeesArrear, ...response });
      });
    }, () => hideLoader(dispatch), true);
  }

  function confirmDeleted() {
    const { month_year = null } = filterResult;
    const payload = {
      employee_id: selectedRow?.EMPLOYEE_ID,
      arrear_payroll_month_year: selectedRow?.arrear_month,
      payroll_month_year: month_year
    }
    post(dispatch, '', 'process/delete_arrear_employee', payload, {}, (res) => {
      let latestArrear = arrearList?.filter((item) => item?.arrear_id != selectedRow?.arrear_id);
      setArrearList([...latestArrear]);
      fetchLatestArrearList();
      setSelectedRow({})
      setIsClickdelete(false);
    })
  }
  return (
    <>
      <CustomTable
        columns={[
          {
            accessorKey: 'arrear_month',
            header: 'Month - Year',
            cell: (data) => (date_format(data, "M - Y")),
            colWidth: '20%'
          },
          {
            accessorKey: 'arrear_id',
            header: 'Is Processed',
            cell: (data) => (<StatusColumn status={data ? "Yes" : "No"} />),
            colWidth: '20%'
          },
          {
            accessorKey: 'NET_PAYABLE',
            header: 'Amount',
            colWidth: '20%'
          },
          {
            accessorKey: 'arrear_id',
            header: 'Action',
            colWidth: '0%',
            cell: (data, row) => (<Stack flexDirection={"row"} gap={1}>
              <CircleButton onClick={() => handleViewArrear(row)}><VisibilityIcon /></CircleButton>
              <If condition={data}>
                <Then>
                  <When condition={!isDeletedHide}>
                    <CircleButton onClick={() => handleDeleteArrear(row)}><DeleteIcon /></CircleButton>
                  </When>
                </Then>
                <Else>
                  <When condition={!isDeletedHide}>
                    <CircleButton style={{ opacity: 0.3, cursor: "not-allowed" }}><DeleteIcon /></CircleButton>
                  </When>
                </Else>
              </If>
            </Stack>)
          },
        ]}
        data={arrearList}
      />
      <CustomModal title="" open={isClickdelete} onclose={() => setIsClickdelete(false)} size="xs">
        <CustomAlert
          labelCancel="Cancel"
          labelProceed="Confirm"
          onCancel={() => setIsClickdelete(false)}
          onProceed={confirmDeleted}
          subtitle={`You want to delete arrear`}
        />
      </CustomModal>

      <CustomModal title={<b>Arrear Breakdown Details</b>} open={isClickview} onclose={() => setIsClickview(false)} size="md">
        <ArrearBreakDownDetails selectedRow={selectedRow} />
      </CustomModal>
    </>
  )
}

export default ArrearBreakDown