import { createTheme, alpha, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./font.css";
import { BpCheckedIcon, BpIcon, BpIntermediateIcon } from '../components/CustomCheckbox/CheckboxIcon';
import { useTranslation, Trans } from '../utilsLang/i18n/i18n'; 
import { HiOutlineChevronUpDown } from "react-icons/hi2";
import { VscClose } from "react-icons/vsc";

// Import locales
import { enUS, esES, arSA, frFR } from '@mui/material/locale';

const ThemeSetup = ({ children }) => {
    const colorConvert = {
        "#335aff": "#FFFFFF",
        "#3B5352": "#FFFFFF",
        "#34004B": "#FFFFFF",
        "#FFFFFF": "#000000",
    }
    const { user } = useSelector(state => state.auth);
    const [primaryColor, setPrimaryColor] = useState(user?.PRODUCT_THEME_COLOR || "#335aff");
    const [secondaryColor, setSecondaryColor] = useState(colorConvert[user?.PRODUCT_THEME_COLOR || "#335aff"] || "#ffffff");
    const [locale, setLocale] = useState(enUS); 

    

    useEffect(() => {
        // Update locale based on user's language or default
        const currentLanguage = user?.language || 'en';
        const getLocaleFromLanguage = (language) => {
            switch(language) {
                case 'es':
                    return esES;
                case 'ar':
                    return arSA;
                case 'fr':
                    return frFR;
                default:
                    return enUS; 
            }
        };
        setLocale(getLocaleFromLanguage(currentLanguage));

        let pcolor = (user.PRODUCT_THEME_COLOR || "#335aff").toUpperCase();
        if (pcolor === '#FFFFFF') {
            pcolor = "#335aff";
        }
        setPrimaryColor(pcolor);
        setSecondaryColor(colorConvert[pcolor] || '#ffffff')
    }, [user])

    const themeOptions = createTheme(
        {
            components: {
                MuiCssBaseline: {
                    styleOverrides: {
                        body: { backgroundColor: "#fafbfc" }
                    }
                },
                MuiCheckbox: {
                    styleOverrides: {
                        root: {
                            color: primaryColor,
                            '&$checked': {
                                color: primaryColor,
                            },
                            '&.Mui-disabled': {
                                color: "#80808078"
                            }
                        }
                    },
                    defaultProps: {
                        icon: <BpIcon />,
                        checkedIcon: <BpCheckedIcon />,
                        indeterminateIcon: <BpIntermediateIcon />,
                        size: "small",
                        disableRipple: true,
                    }
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            boxShadow: "0px 0px 3px 0px rgb(0 0 0 / 12%)",
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            fontSize:14,
                            "& .MuiFormLabel-root": {
                                backgroundColor: "white",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#e4e4e7"
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0px !important",
                                boxShadow: "0px 0px 1px 1px #b4aeaee6",
                            },
                            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0px !important",
                                boxShadow: "0px 0px 3px 1px #b4aeaee6",
                            }
                        }
                    },
                    defaultProps: {
                        inputProps: {
                            sx: { fontSize: 14 }
                        }
                    }
                },
                MuiAutocomplete: {
                    styleOverrides: {
                        input: {
                            fontSize: 14
                        },
                        paper: {
                            borderRadius: "0.25rem",
                            border: "1px solid #e4e4e7"
                        },
                        option: {
                            padding: "6px 10px !important",
                            fontSize: 14,
                        },
                        root: {
                            fontSize: 12,
                            "& .MuiFormLabel-root": {
                                backgroundColor: "white",
                            }
                        }
                    },
                    defaultProps: {
                        componentsProps: {
                            paper: {
                                sx: {
                                    border: "1px solid gray",
                                }
                            }
                        },
                        popupIcon: <HiOutlineChevronUpDown style={{ height: 18 }} />,
                        clearIcon: <VscClose style={{ height: 18 }} />
                    }
                }
            },
            typography: {
                fontFamily: '"Segoe UI Web (West European)"',
                letterSpacing: "0px!important",
                h1: {
                    fontSize: 32,
                },
                h2: {
                    fontSize: 26,
                },
                h3: {
                    fontSize: 24,
                },
                h4: {
                    fontSize: 20,
                },
                h5: {
                    fontSize: 16,
                    fontWeight: 600
                },
                h6: {
                    fontSize: 14,
                },
                button: {
                    textTransform: 'none',
                    height: 35,
                }
            },
            zIndex: {
                drawer: 1300
            },
            palette: {
                background: {
                    primary: alpha(primaryColor, 1),
                    secondary: alpha(primaryColor, 0.5)
                },
                primary: {
                    main: primaryColor,
                    other: secondaryColor,
                    menu: alpha(secondaryColor, 0.7),
                },
                listhead: {
                    main: "#ECECEC"
                }
            },
            spacing: 8,
            shadows: ['none', `0 0 2px 0 ${alpha('#919EAB', 0.24)}, -20px 20px 40px -4px ${alpha('#919EAB', 0.24)}`].concat(Array(23).fill('none')),
            overrides: {
                MuiAppBar: {
                    colorInherit: {
                        backgroundColor: primaryColor,
                        color: secondaryColor,
                    },
                }
            },
            props: {
                MuiTooltip: {
                    arrow: true,
                },
                MuiButton: {
                    size: 'small',
                },
                MuiButtonGroup: {
                    size: 'small',
                },
                MuiCheckbox: {
                    size: 'small',
                },
                MuiFab: {
                    size: 'small',
                },
                MuiFormControl: {
                    margin: 'dense',
                    size: 'small',
                },
                MuiFormHelperText: {
                    margin: 'dense',
                },
                MuiIconButton: {
                    size: 'small',
                },
                MuiInputBase: {
                    margin: 'dense',
                },
                MuiInputLabel: {
                    margin: 'dense',
                },
                MuiRadio: {
                    size: 'small',
                },
                MuiSwitch: {
                    size: 'small',
                },
                MuiTextField: {
                    margin: 'dense',
                    size: 'small',
                },
                MuiList: {
                    dense: true,
                },
                MuiMenuItem: {
                    dense: true,
                },
                MuiTable: {
                    size: 'small',
                },
                MuiButtonBase: {
                    disableRipple: true,
                },
                MuiAppBar: {
                    color: 'transparent',
                }
            },
        },
        // Apply the selected locale
        locale
    );

    return (
        <ThemeProvider theme={themeOptions}>
            {children}
        </ThemeProvider>
    )
}

export default ThemeSetup;